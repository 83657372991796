import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { DEFAULT_CURRENCY, formatAmount } from '../../utils/currency';

import 'rc-slider/assets/index.css';
import styles from './index.module.scss';


const PriceRangeFilter = ({ onPriceRangeChange, initialPriceRange }) => {
  /**
   * This is a workaround to group prices for better UX.
   * Marks config control dots on the price range slider.
   * Keys on the left are dot positions evenly spaced from each other and also slider range values.
   * Values on the right are actual price values.
   * This needs to be addressed in: https://www.pivotaltracker.com/story/show/158291213
   */
  const marks = {
    0: 0,
    1: 20,
    2: 40,
    3: 60,
    4: 80,
    5: 100,
    6: 150,
    7: 500,
    8: 1000,
    9: 1500,
  };

  const onSliderChange = (value) => {
    const priceRange = [marks[value[0]], marks[value[1]]];
    onPriceRangeChange(priceRange);
  };

  const getInitialSliderRangeValue = () => {
    // Calculating initial Slider range value from price range.
    const initialSliderRangeValue = [];
    Object.keys(marks).forEach((key) => {
      if (marks[key] === initialPriceRange[0]) {
        initialSliderRangeValue[0] = parseInt(key, 10);
      }

      if (marks[key] === initialPriceRange[1]) {
        initialSliderRangeValue[1] = parseInt(key, 10);
      }
    });

    return initialSliderRangeValue;
  };
  const initialSliderRangeValue = getInitialSliderRangeValue();
  return (
    <div className={styles['price-range-filter']} data-analytics={'prices-slider'}>
      <div className={styles['price-range-filter__header']}>
        <span className={styles['price-range-filter__title']}>Price range</span>
        <span className={styles['price-range-filter__values']}>
          {formatAmount(DEFAULT_CURRENCY, initialPriceRange[0])} -{' '}
          {formatAmount(DEFAULT_CURRENCY, initialPriceRange[1])}
        </span>
      </div>
      <Slider
        range
        marks={marks}
        allowCross={false}
        step={1}
        defaultValue={[initialSliderRangeValue[0], initialSliderRangeValue[1]]}
        min={0}
        max={9}
        onChange={onSliderChange}
        pushable
      />
    </div>
  );
};

PriceRangeFilter.propTypes = {
  onPriceRangeChange: PropTypes.func.isRequired,
  initialPriceRange: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default PriceRangeFilter;
