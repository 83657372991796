import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';

import styles from './index.module.scss';

const Select = ({ onChange = () => true, initialValue = null, options, ...props }) => {
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [expanded, setExpanded] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (!options.find((item) => item.key === currentValue)) {
      setCurrentValue(options[0].key);
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setExpanded(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className={styles['select']} ref={wrapperRef} {...props}>
      <div className={styles['select__current']}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setExpanded(!expanded);
          }}
        >
          {options.find((item) => item.key === currentValue)?.value}
          <span
            className={`material-icons chevron_right ${styles['select__current-icon']} ${expanded ? styles['select__current-icon--expanded'] : ''}`}
          />
        </a>
      </div>
      {expanded && (
        <div className={`select__options ${styles['select__options']}`}>
          {options.map((item) => (
            <div
              key={item.key}
              className={`${styles['select__option']} ${item.key === currentValue ? styles['select__option--current'] : ''}`}
              onClick={() => {
                setCurrentValue(item.key);
                setExpanded(false);
                onChange(item.key);
              }}
            >
              {item.href && (
                <Link href={item.href ?? '#'} scroll={false}>
                  <a
                    className={`${item.highlightedColor ? styles['select__option--highlighted-' + item.highlightedColor] : ''}`}
                  >
                    {item.value}
                  </a>
                </Link>
              )}
              {!item.href && (
                <div
                  className={`${styles['select__option--label']} ${item.highlighted ? styles['select__option--highlighted'] : ''}`}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {item.value}
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Select.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Select;
