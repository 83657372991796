import React from 'react';
import PropTypes from 'prop-types';
import CategoryFilterIE from './IE/index';
import CategoryFilterUK from './UK/index';
import { DEFAULT_COUNTRY_CODE } from '../../utils/constants';

const CategoryFilter = ({ ...props }) => {
  if (DEFAULT_COUNTRY_CODE === 'IE') {
    return <CategoryFilterIE {...props} />;
  } else {
    return <CategoryFilterUK {...props} />;
  }
};

CategoryFilter.propTypes = {
  categories: PropTypes.object.isRequired,
  activeCategoryId: PropTypes.number.isRequired,
};

export default CategoryFilter;
