import React from 'react';
import BBCatalogWithFiltersUK from './UK/index';
import BBCatalogWithFiltersIE from './IE/index';
import { DEFAULT_COUNTRY_CODE } from '../../../utils/constants';

const BBCatalogWithFilters = ({ ...props }) => {
  if (DEFAULT_COUNTRY_CODE === 'IE') {
    return <BBCatalogWithFiltersIE {...props} />;
  } else {
    return <BBCatalogWithFiltersUK {...props} />;
  }
};

export default BBCatalogWithFilters;
