import React, { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import CategoryFilter from '../../../CategoryFilter';
import { CategoriesContext, OriginalUrlContext } from '../../../../utils/context';
import { DEFAULT_CURRENCY } from '../../../../utils/currency';
import { useSelector } from 'react-redux';
import CatalogOrderBy from '../../../CatalogOrderBy';
import GiftItem from '../../../GiftItem';

import s from './index.module.scss';

const BBCatalogWithFilters = () => {
  const products = useSelector((state) => state.productsStorage.gifts);
  const categories = useContext(CategoriesContext);
  const originalUrl = useContext(OriginalUrlContext);
  // Values should be stored globally to be able to reuse them when category changes.
  // TODO: Move it to redux store when it is ready.
  const router = useRouter();
  const [orderBy, setOrderBy] = useState(router.query.orderBy ?? 'popular');

  const activeCategory = Object.values(categories).filter(
    (item) => item.url.url === originalUrl.split('?')[0],
  );

  let pageTitle = 'Explore our Gifts cards';
  let activeCategoryId;
  if (activeCategory.length) {
    const currentCategory = activeCategory[0];
    activeCategoryId = currentCategory.id;
    pageTitle = currentCategory.pageTitle
      ? currentCategory.pageTitle
      : `Explore our ${currentCategory.title.replace('Bundles', 'Bundle')} cards`;
  }

  const filteredProducts = Object.values(products).filter((item) => {
    if (!item.categoryIds.includes(activeCategoryId)) {
      return false;
    }

    return true;
  });

  const sortedProducts = filteredProducts.sort((a, b) => {
    if (orderBy === 'high-to-low') {
      return b.price[DEFAULT_CURRENCY].amount - a.price[DEFAULT_CURRENCY].amount;
    } else if (orderBy === 'low-to-high') {
      return a.price[DEFAULT_CURRENCY].amount - b.price[DEFAULT_CURRENCY].amount;
    } else if (orderBy === 'new') {
      return b.id - a.id;
    }

    // Use "popular" by default.
    return a.fieldBestSellerWeight - b.fieldBestSellerWeight || a.fieldWeight - b.fieldWeight;
  });

  const gifts = sortedProducts.map((product) => (
    <GiftItem product={product} key={product.id} variant="modern" />
  ));

  return (
    <>
      <div className="bb">
        <div className="container">
          <h1 className={s['bb-catalog-with-filters__title']} data-component="uk-catalog-title">
            {pageTitle}
          </h1>
        </div>
      </div>
      <div className={`bb ${s['bb-catalog-with-filters']}`}>
        <div className="container">
          <div className={s['bb-catalog-with-filters__container']}>
            <div className={s['bb-catalog-with-filters__menu']}>
              {categories && Object.values(categories).length && activeCategoryId && (
                <CategoryFilter categories={categories} activeCategoryId={activeCategoryId} />
              )}
              <CatalogOrderBy
                initialValue={orderBy}
                onChange={(value) => {
                  setOrderBy(value);
                  window.history.pushState(
                    null,
                    null,
                    `${router.asPath.split('?')[0]}?orderBy=${value}`,
                  );
                }}
              />
            </div>
            <div className={s['bb-catalog-with-filters__gifts-grid']}>
              {gifts.length > 0 ? (
                gifts
              ) : (
                <div className={s['bb-catalog-with-filters__no-results']}>
                  <h5>
                    There are no gifts in this price range. Please select another price range.
                  </h5>
                  <h5>Thank you.</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BBCatalogWithFilters;
