import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import Select from '../Select';

const CatalogOrderBy = ({ onChange, initialValue }) => {
  return (
    <div className={styles['catalog-order-by']} data-analytics={'catalog-order-by'}>
      <div className={styles['catalog-order-by__label']}>Order by</div>
      <div className={styles['catalog-order-by__select']}>
        <Select
          onChange={onChange}
          initialValue={initialValue}
          options={[
            {
              key: 'high-to-low',
              value: 'High to low',
            },
            {
              key: 'low-to-high',
              value: 'Low to high',
            },
            {
              key: 'popular',
              value: 'Popular',
            },
            {
              key: 'new',
              value: 'New',
            },
          ]}
        />
      </div>
    </div>
  );
};

CatalogOrderBy.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
};

export default CatalogOrderBy;
